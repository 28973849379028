import Vue from 'vue';
import { Stepper, Toast } from 'vant';
import {mapActions} from 'vuex';
import { Checkbox, CheckboxGroup } from 'vant';
import { Button } from 'vant';
import { SubmitBar } from 'vant';
import { Card } from 'vant';
import { Dialog } from 'vant';


Vue.use(Dialog);
Vue.use(Stepper);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Button);
Vue.use(SubmitBar);
Vue.use(Card);

export default {
  name: 'shopCar',

  data() {

    return {
      show_num:0,
      cartStatus: "account",  //购物车状态，account结算，edit删除编辑状态
      value: 1,
      allchecked: false,
      checkedGroup: [],
      cartlist: [],
      shopcard: [],
      isempty:false,
      jiesuan1:'结算',
      shixiaoList:[]
    };
  },
  watch: {
    $route() {
    // 在mounted函数执行的方法，放到该处
    this.getLists();
    }
    },

  created() {
    this.getLists()
  //   this.$router.go(0)
  },
  updated() {
    if(this.checkedGroup.length>0){
      this.jiesuan1='结算('+this.checkedGroup.length+')'
    }else{
      this.jiesuan1='结算'
    }

  },
  computed: {
    //computed中，判断全选状态
    isAllChecked: {
      get: function () {
        if (this.checkedGroup.length === this.shopcard.length && this.shopcard.length !== 0) { //选中数与商品总数相等并且购物车内存在商品
          return true //表示全选状态
        } else {
          return false //表示未全选
        }
      },
      set: function (newValue) {
        this.$store.state.isAllChecked = newValue;
      }
    },

    allcount() {
      let sumup = 0
      for (let i = 0; i < this.shopcard.length; i++) {
        if (this.checkedGroup.find(item => { //判断是否选中商品，未选中不计算在内
          return item === this.shopcard[i].flProductId
        })) {
          sumup += this.shopcard[i].productNumber * parseFloat(this.shopcard[i].flProductPrice) * 100
        } else {
          continue
        }
      }
      return sumup
    },
    totalprice() {
      let count = 0;
      var cartlist = this.cartlist;
      for (let i in cartlist) {
        for (let j in cartlist[i].goodlist) {
          if (cartlist[i].goodlist[j].gdcheck == true) {
            count += parseFloat(
              cartlist[i].goodlist[j].productNumber * cartlist[i].goodlist[j].flProductPrice
            );
          }
        }
      }
      return count * 100;
    },
  },
  methods: {
    ...mapActions('shopList', ['clearList','getShopList','editCart','delCart','checkShop']),
    back(){
      if(this.$route.query.againBuy||localStorage.getItem('shopCarBack')==0||localStorage.getItem('shopCarBack')==1||this.$route.query.back==6){
        this.$router.push({name:'corporateWelfare'})
      }else{

        this.$router.go(-1);
      }
    },
    goTo(){
      this.$router.push({name:'corporateWelfare'});
      this.cartStatus="account"
    },
    clearAll(){
      Dialog.confirm({
        title: '提示',
        message: '确认要清空失效宝贝吗？',
      })
        .then(() => {
          this.clearList().then(res=>{
            if(res.code==200){
              this.getLists()

            }else{
              Toast(res.msg)
            }

          })
        })
        .catch(() => {
          // on cancel
        });


    },
    getLists(){

      this.getShopList().then(res=>{
        if(res.code==200){
          this.shopcard=res.data.content;
          this.shixiaoList=res.data.sxContent
          if(this.shopcard.length<=0&&this.shixiaoList.length<=0){
            this.isempty=true
          }else{
            this.isempty=false
          }
        }else{
          Toast(res.msg)
        }
      })
    },
    godetail(id){
      localStorage.setItem('detailBack','0')
      this.$router.push({name:'corwelDetail',query:{pid:id,back:this.$route.query.back}});
    },

    changeNum(num, item) {
      this.editCart({
        number:num,
        pid:item.flProductId
      }).then(res=>{
        if(res.code==200){
        }else{
          Toast(res.msg)
        }
      })
    },
    //methods中，点击全选按钮时
    checkAll() {
      if (this.checkedGroup.length !== 0) { //有商品卡片选中
        if (this.checkedGroup.length === this.shopcard.length) { //总商品数与选中商品卡片数相等，说明此时已全选
          this.$refs.checkboxGroup.toggleAll(false); //令所有选中的反选，即全不选
        } else { //商品卡片数部分选中
          this.$refs.checkboxGroup.toggleAll(true); //令所有商品全部选中
        }
      } else { //没有商品卡片选中，直接全选
        this.$refs.checkboxGroup.toggleAll(true);
      }
    },
    addnum(item) {
      this.shopcard.find(element => {
        return element.title === item.title
      }).num = parseInt(item.num) + 1
    },
    minusnum(item) {
      this.shopcard.find(element => {
        return element.title === item.title
      }).num = parseInt(item.num) - 1
    },
    edit() {

    },
    onSubmit(){
      let checked2=''
      for(let i=0;i<this.checkedGroup.length;i++){
        checked2+=this.checkedGroup[i]
        if(i<this.checkedGroup.length-1){
          checked2+=','
        }
      }
      if(this.checkedGroup.length<=0){
        Toast('至少选择一个商品！');
      }else{
        localStorage.setItem('pid',checked2)
        this.$router.push({name:'orderSure',query:{payType:0,orderNo:''}});

      }
    },
    del() {
      if(this.checkedGroup.length<=0){
        Toast('至少选择一个商品！');
      }else{
        Dialog.confirm({
          message: '确定要删除所选产品吗？',
        })
          .then(() => {
            // on confirm
            let checked1=''
            for (let i = 0; i < this.checkedGroup.length; i++ ) {
              checked1 += this.checkedGroup[i]
              if (i < this.checkedGroup.length-1) {
                checked1 += ','
              }
            }
            const my=this
            this.delCart({
              pid:checked1
            }).then(res=>{
              if (res.code==200) {
                Toast('删除成功！');
                this.checkedGroup=[]
                this.getLists();

                this.cartStatus='account'

            //    this.$router.go(0)
              }
            })


          })
          .catch(() => {
            // on cancel
          });
      }



    }
  },

}

